import styled from 'styled-components';

export const FormWrapper = styled.div`
${tw`px-4 py-4`};
max-width: 660px;
width: 100%;
margin: 0 auto;

    ${props =>
    props.loading &&
    `
        cursor: not-allowed;
        opacity: 0.2;
        user-select: none;
        pointer-events: none;
    `}

  .form-input-row {
      display: flex;
      width: 100%;
      div {
          flex: 1;
          
          &:first-of-type {
              max-width: 40%;
          }

          &:first-of-type label input {
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
          }
          &:last-of-type label input {
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
          }
      }
  }

`

export const ErrorSpan = styled.span`
${tw`px-4 py-1 bg-red background-red color-white text-white w-100 display-block mb-1`};
display: inline-block;
width: 100%;
border-radius: 2px;
margin: 0 auto;
margin-bottom: 20px;
font-size: 13px;
text-align: left;
position: relative;
top: -7px;
`

export const SelectStyle = styled.select`
${tw`mb-2 display-block w-100 rounded-sm text-base text-sm py-2 px-4 border-gray-400 focus:border-gray-600 border-1`};
width: 100%;
border: 1px solid #eee;
`