import React from 'react'
import PropTypes from 'prop-types'
import { InputFieldStyle, FormFieldStyle } from '../styles/common'

export default function FormField({type, name, id, required, placeholder, inputRef}) {
    return(
    <FormFieldStyle>
        <label htmlFor={name}>
            <InputFieldStyle type={type} id={id} name={name} placeholder={placeholder} required={required} ref={inputRef}/>
        </label>
    </FormFieldStyle>
    )
}

FormField.defaultProps = {}
  
FormField.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    inputRef: PropTypes.func.isRequired,
}