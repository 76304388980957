import React from 'react'
import { useForm } from "react-hook-form"
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import Button from '../components/button'
import H1 from '../components/h1'
import FormField from '../components/formfield'
import MainWrap from '../components/mainwrap'
import CheckBox from '../components/checkbox'
import { Margin } from 'styled-components-spacing';
import { useMutation } from 'react-apollo'
import { ADD_ENTRY_MUTATION } from '../graphql/mutations'
import { FormWrapper, SelectStyle, ErrorSpan } from '../styles/pages/registration'
import { ErrorMsg } from '../styles/common'

export default function Registration() {

const { register, handleSubmit, errors, reset } = useForm();
const [addEntry, { loading, error }] = useMutation(ADD_ENTRY_MUTATION);

const onSubmit = (formData) => { 
    const { firstName, lastName, email, location, newsletter, address, zip, mobile, city } = formData;
    addEntry({ variables: {
        request: { 
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "mobile": mobile,
                    "zip": zip,
                    "city": city,
                    "address": address,
                    "campaignId": process.env.SANITYCAMPAIGN_ID,
                    "acceptNewsletter": newsletter,
                    "customFields": [
                    {
                        "name": "spilleSted", 
                        "value": location
                    }
                    ]
            }
        }
     })
     .then((res) => {
         reset(); 
         localStorage.setItem('userId', res.data.addEntry.id );
         navigate('/thanks');
     })
     .catch(err => console.log(err));

};

return(

    <Layout>
        <MainWrap>
        
        <H1 title="Fyll ut skjema, og bli med i trekningen"  subtitle="Vel gjennomført!">        
        </H1>
        
        
        {loading && <p>Sender registrering...</p>}
        {error && (
                <ErrorMsg>😡 Noe gikk galt.. prøv igjen senere</ErrorMsg>
              )}

        <form onSubmit={handleSubmit(onSubmit, error)}>
        <FormWrapper>

        <FormField id="firstName" name="firstName" placeholder="Skriv inn fornavn (påkrevd)" label="Skriv inn fornavn (påkrevd)" type="text" inputRef={register({ required: true })}/>
        {errors.firstName && <ErrorSpan>Fornavn er påkrevd.</ErrorSpan>}

        <FormField id="lastName" name="lastName"  placeholder="Skriv inn etternavn (påkrevd)" label="Skriv inn etternavn (påkrevd)" type="text"  inputRef={register({ required: true })}/>
        {errors.lastName && <ErrorSpan>Etternavn er påkrevd.</ErrorSpan>}

        <FormField id="address" name="address"  placeholder="Skriv inn gateadresse" label="Skriv inn gateadresse" type="text"  inputRef={register()}/>
        <div className="form-input-row">
            <FormField id="zip" name="zip"  placeholder="Skriv inn postnummer" label="Skriv inn postnummer" type="number"  inputRef={register()}/>
            <FormField id="city" name="city"  placeholder="Skriv inn poststed" label="Skriv inn poststed" type="text"  inputRef={register()}/>
        </div>

        <FormField id="email" name="email" placeholder="Skriv inn e-post (påkrevd)" label="Skriv inn e-post (påkrevd)" type="email"  inputRef={register({ required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ })}/>
        {errors.email && <ErrorSpan>E-post er påkrevd.</ErrorSpan>}

        <FormField id="mobile" name="mobile"  placeholder="Skriv inn telefonnummer" label="Skriv inn telefonnummer (påkrevd)" type="telephone"  inputRef={register({ required: false,  maxLength: 8, minLength: 8  })} />
        {errors.mobile && <ErrorSpan>Telefonnummeret må være 8 siffer, og ikke inneholde +47.</ErrorSpan>}

         <SelectStyle id="location" name="location"  placeholder="Velg nærmeste spillested" ref={register({ required: true })} defaultValue="">
         <option value="" disabled selected>Velg nærmeste spillested</option>
         <option value="Alta">Alta</option>
         <option value="Biri">Biri</option>
         <option value="Brandbu">Brandbu</option>
         <option value="Bodø">Bodø</option>
         <option value="Dokka">Dokka</option>
         <option value="Fredrikstad">Fredrikstad</option>
         <option value="Gardsenteret">Gardsenteret</option>
         <option value="Haugesund">Haugesund</option>
         <option value="Hillevåg">Hillevåg</option>
         <option value="Hønefoss">Hønefoss</option>
         <option value="Kleppe">Kleppe</option>
         <option value="Kongsberg">Kongsberg</option>
         <option value="Kopervik">Kopervik</option>
         <option value="Laksevåg">Laksevåg</option>
         <option value="Minde">Minde</option>
         <option value="Molde">Molde</option>
         <option value="Randaberg">Randaberg</option>
         <option value="Rundtom">Rundtom</option>
         <option value="Sandvika">Sandvika</option>
         <option value="Sarpsborg">Sarpsborg</option>
         <option value="Skien">Skien</option>
         <option value="Stavanger">Stavanger</option>
         <option value="Stokke">Stokke</option>
         <option value="Ålesund">Ålesund</option>
         <option value="Åsane">Åsane</option>
         <option value="Åssiden">Åssiden</option>
         </SelectStyle>
        {errors.location && <ErrorSpan>Velg nærmeste spillsted</ErrorSpan>}
        
        <CheckBox id="newsletter" name="newsletter" inputRef={register} text="Jeg ønsker å motta nyhetsbrev fra Spilleriet. og at min epost blir lagret."/>
        <p><small>Ved å delta aksepterer du at vi lagrer din info for å kunne utføre trekningen.
Informasjonen vil bli slettet etter at konkurransen er avsluttet.</small></p>
        <Margin top="4">
            <Button title="Delta i trekningen" handleClick={ () => {  }} answer={null} disabled={null} outline={true}/>
        </Margin>
        </FormWrapper>
        </form>
        </MainWrap>

    </Layout>
)}