import React from 'react'
import PropTypes from 'prop-types'
import { LabelWrap } from '../styles/common'

export default function CheckBox({id, name, text, inputRef}) {
    return(
            <LabelWrap>
                <input className="option__input" type="checkbox" id={id} name={name} ref={inputRef}/>
                <span className="option__text">{text}</span>
            </LabelWrap>
    )
};

  CheckBox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    inputRef: PropTypes.func.isRequired,

}